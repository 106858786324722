@tailwind base;
@tailwind components;
@tailwind utilities;

/* scrollbar styling */

/* Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #114477 #0f172a;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 14px;
}

*::-webkit-scrollbar-track {
	background: #0f172a;
}

*::-webkit-scrollbar-thumb {
	background-color: #114477;
	border: 3px solid #0f172a;
}
